import React from 'react'
import classNames from 'classnames'

const Button = ({
  className,
  children,
  tabIndex = -1,
  hover = '',
  onKeyDown = () => {},
  ...rest
}) => {
  const hoverClasses = {
    fade: 'hover-fade',
  }
  return (
    <div
      role="button"
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      className={classNames('button', hoverClasses[hover], className)}
      {...rest}
    >
      {typeof children === 'string' ? (
        <span className="button-text">{children}</span>
      ) : (
        children
      )}
    </div>
  )
}

export default Button
