import create from 'zustand'
import { createInitialState } from './initial-state'
import { createActions } from './actions'

export const useStore = create((set) => ({
  ...createInitialState(),
  ...createActions(set),
}))

try {
  Object.defineProperty(window, 'state', {
    get: function () {
      return useStore.getState()
    },
  })
} catch (err) {
  // noop
}
