import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import Link from 'src/components/link'
import Picture from 'src/components/picture'
import Button from 'src/components/button'
import CartDrawer from 'src/components/cart-drawer'
import { useStore } from 'src/state'

const Header = () => {
  const {
    shouldShowNav,
    isScrolled,
    setHeaderMenuHeight,
    toggleCart,
    checkout,
    header: { logo, cartButtonText, backToText, backToUrl },
  } = useStore()

  const [currentLinkId, setCurrentLinkId] = useState(null)
  const menuRef = useRef()
  if (!shouldShowNav && currentLinkId) setCurrentLinkId(null)
  useEffect(() => {
    const height = menuRef.current.offsetHeight
    setHeaderMenuHeight(height)
  }, [setHeaderMenuHeight])
  const hasItemsInCart = checkout && checkout.lineItems.length > 0
  const cartTextWithCount = hasItemsInCart
    ? `${cartButtonText} [${checkout.totalQuantity}]`
    : cartButtonText
  return (
    <header
      className={classNames(
        'header',
        shouldShowNav && 'is-open',
        isScrolled && 'is-scrolled'
      )}
    >
      <div className="gradient-background" />
      <div className="menu" ref={menuRef}>
        <div className="menu-start">
          <Button>
            <a
              className="button-text"
              href={backToUrl}
              target="_blank"
              rel="noreferrer"
            >
              {backToText}
            </a>
          </Button>
        </div>
        <div className="menu-middle">
          <Link className="logo" to="/">
            <Picture defaultWidth={500} image={logo} contain fitWidth />
          </Link>
        </div>
        <div className="menu-end">
          <Button className="cart-btn" onClick={toggleCart}>
            {cartTextWithCount}
          </Button>
        </div>
      </div>
      <CartDrawer />
    </header>
  )
}

export default Header
