import { globalHistory } from '@reach/router'
import Cookies from 'js-cookie'
import { useStore } from 'src/state'
import { getCheckout } from 'src/api/shopify-client-api'
import 'src/styles/index.sass'

const {
  hideNav,
  setWindowWidth,
  setWindowHeight,
  setPagePaddingX,
  setIsScrolled,
  setScrollY,
  setContainerPaddingX,
  setIsScrolling,
  hideTrainerCardOverlay,
  setHeaderMenuHeight,
  setCheckout,
  hideCart,
  setSiteLoaded,
  setPagePaddingTop,
  setPagePaddingBottom,
  setSectionPaddingBig,
  setSectionPaddingSmall,
  setFormOverlayId,
  showFormOverlay,
  // formReloadDelay,
  setButtonPaddingTop,
  setButtonPaddingBottom,
  setIsWindowLoaded,
  popupDelay,
} = useStore.getState()

export const shouldUpdateScroll = () => {
  return false
}

getCheckout().then((checkout) => {
  setCheckout(checkout)
})

globalHistory.listen(({ action }) => {
  if (action === 'PUSH') {
    hideTrainerCardOverlay()
  }
})

globalHistory.listen(({ action }) => {
  if (action === 'PUSH') {
    hideCart()
  }
})

globalHistory.listen(({ action }) => {
  if (action === 'PUSH') {
    hideNav()
  }
})

window.addEventListener('load', () => {
  setIsWindowLoaded(true)
})

setWindowWidth(window.innerWidth)
window.addEventListener('resize', () => {
  setWindowWidth(window.innerWidth)
})

setWindowHeight(window.innerHeight)
window.addEventListener('resize', () => {
  setWindowHeight(window.innerHeight)
})

setButtonPaddingTop(window.innerWidth)
window.addEventListener('resize', () => {
  setButtonPaddingTop(window.innerWidth)
})

setButtonPaddingBottom(window.innerWidth)
window.addEventListener('resize', () => {
  setButtonPaddingBottom(window.innerWidth)
})

setPagePaddingX(window.innerWidth)
window.addEventListener('resize', () => {
  setPagePaddingX(window.innerWidth)
})

setContainerPaddingX(window.innerWidth)
window.addEventListener('resize', () => {
  setContainerPaddingX(window.innerWidth)
})

setScrollY(window.scrollY)
window.addEventListener('scroll', () => {
  setScrollY(window.scrollY)
})

setIsScrolled(window.scrollY)
window.addEventListener('scroll', () => {
  setIsScrolled(window.scrollY)
})

const getHeaderHeight = () => {
  const menu = document.querySelector('.header .menu')
  return menu ? menu.offsetHeight : 0
}
window.addEventListener('load', () => setHeaderMenuHeight(getHeaderHeight()))
window.addEventListener('resize', () => setHeaderMenuHeight(getHeaderHeight()))
window.addEventListener('scroll', () => setHeaderMenuHeight(getHeaderHeight()))

window.addEventListener('load', () => {
  setSiteLoaded(true)
})

window.addEventListener('klaviyoForms', (event) => {
  const {
    general: { notifyMeFormId },
  } = useStore.getState()
  const isNotifyMeForm =
    `klaviyo-form-${event.detail.formId}` === notifyMeFormId
  const isSubmission =
    event.detail.type === 'submit' || event.detail.type === 'redirectedToUrl'
  if (isSubmission && isNotifyMeForm) {
    const {
      notifyMeProductHandle,
      general: { notifyMeFormName },
    } = useStore.getState()
    const data = {
      formId: event.detail.formId,
      productHandle: notifyMeProductHandle,
      email: event.detail.metaData.$email,
    }
    window._learnq.push(['track', notifyMeFormName, data])
    console.info(notifyMeFormName, notifyMeFormId, data)
    // window.setTimeout(
    //   () => (window.location = `${window.location}`),
    //   formReloadDelay
    // )
  }
})

window.addEventListener('resize', () => {
  setPagePaddingX(window.innerWidth)
  setPagePaddingTop(window.innerWidth)
  setPagePaddingBottom(window.innerWidth)
  setSectionPaddingBig(window.innerWidth)
  setSectionPaddingSmall(window.innerWidth)
})

const attempToShowPopup = () => {
  console.log('attempToShowPopup...')
  const {
    shouldShowFormOverlay,
    general: { delayedPopupFormId },
  } = useStore.getState()
  if (!delayedPopupFormId) {
    console.info('No Delayed Popup Form ID')
    return
  }
  const isIgnoredPath = ['/app/', '/app', '/balmain/', '/balmain'].includes(
    window.location.pathname
  )
  if (isIgnoredPath) {
    console.info(`Not Showing Popup For ${window.location.pathname}`)
    return
  }
  const hasShownDelayedPopup =
    Cookies.get(`delayed-popup-${delayedPopupFormId}`) === 'true'
  if (hasShownDelayedPopup) {
    console.info('Already Showed Delayed Popup')
    return
  }
  if (shouldShowFormOverlay) {
    console.info(`Form Overlay Already Open. Retry after ${popupDelay}ms`)
    setTimeout(attempToShowPopup, popupDelay)
    return
  }
  console.info('Show Delayed Popup', delayedPopupFormId)
  Cookies.set(`delayed-popup-${delayedPopupFormId}`, true, { expires: 7 })
  setFormOverlayId(delayedPopupFormId)
  showFormOverlay()
}
window.addEventListener('load', () => setTimeout(attempToShowPopup, popupDelay))

window.clearPopupCookie = () => {
  const {
    general: { delayedPopupFormId },
  } = useStore.getState()
  Cookies.remove(`delayed-popup-${delayedPopupFormId}`)
}

let prevScrollY = window.scrollY
const checkIfScrolling = () => {
  const currentIsScrolling = useStore.getState().isScrolling
  const isScrolling = Math.abs(window.scrollY - prevScrollY) > 0
  currentIsScrolling !== isScrolling && setIsScrolling(isScrolling)
  prevScrollY = window.scrollY
  requestAnimationFrame(checkIfScrolling)
}
checkIfScrolling()

useStore.subscribe(({ shouldShowCart }) => {
  shouldShowCart
    ? document.body.classList.add('showing-cart')
    : document.body.classList.remove('showing-cart')
})
