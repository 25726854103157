import React from 'react'
import classNames from 'classnames'
import { useStore } from 'src/state'
import Picture from 'src/components/picture'
import Link from 'src/components/link'
import BoxButton from 'src/components/box-button'
import Button from 'src/components/button'
import AspectBox from 'src/components/aspect-box'
import { removeFromCart, updateQuantity } from 'src/api/shopify-client-api'

const CartDrawer = () => {
  const {
    shouldShowCart,
    checkout,
    setCheckout,
    hideCart,
    setIsWorking,
    general: {
      removeFromCartText,
      cartCheckoutText,
      cartSubtotalText,
      cartEmptyText,
    },
  } = useStore()
  if (!checkout) return null
  const isEmpty = checkout.lineItems.length < 1
  return (
    <div className={classNames('cart-drawer', shouldShowCart && 'visible')}>
      <div className="cart-drawer-content">
        {isEmpty ? (
          <div className="cart-without-items">
            <h3 className="size-big centered">{cartEmptyText}</h3>
          </div>
        ) : (
          <div className="cart-with-items">
            <div className="line-items">
              {checkout.lineItems.map((lineItem) => (
                <div key={lineItem.id} className="line-item">
                  <AspectBox x={277} y={365} className="thumb">
                    <Link
                      to={`/shop/products/${lineItem.variant.product.handle}`}
                    >
                      <Picture
                        defaultWidth={500}
                        image={lineItem.variant.image}
                        fitWidth
                        cover
                      />
                    </Link>
                  </AspectBox>
                  <div className="details">
                    <Link
                      to={`/shop/products/${lineItem.variant.product.handle}`}
                      className="details-start"
                    >
                      <h3 className="product-title size-medium">
                        {lineItem.title}
                      </h3>
                      <h3 className="variant-title">
                        {lineItem.variant.title}
                      </h3>
                      <p className="subtotal-price">
                        {lineItem.variant.prettyPrice}
                      </p>
                    </Link>
                    <div className="details-end">
                      <div className="quantity-stepper">
                        <BoxButton
                          onClick={async () => {
                            setIsWorking(true)
                            const newCheckout = await updateQuantity(
                              lineItem.id,
                              lineItem.quantity - 1
                            )
                            newCheckout && setCheckout(newCheckout)
                            setIsWorking(false)
                          }}
                          className={classNames(
                            'step-btn',
                            'decrease-btn',
                            lineItem.quantity < 2 && 'disabled'
                          )}
                          dark
                        >
                          -
                        </BoxButton>
                        <BoxButton className="quantity" darker>
                          {lineItem.quantity}
                        </BoxButton>
                        <BoxButton
                          onClick={async () => {
                            setIsWorking(true)
                            const newCheckout = await updateQuantity(
                              lineItem.id,
                              lineItem.quantity + 1
                            )
                            newCheckout && setCheckout(newCheckout)
                            setIsWorking(false)
                          }}
                          className="step-btn increase-btn"
                          dark
                        >
                          +
                        </BoxButton>
                      </div>
                      <BoxButton
                        className="remove-btn"
                        onClick={async () => {
                          setIsWorking(true)
                          const newCheckout = await removeFromCart(lineItem.id)
                          newCheckout && setCheckout(newCheckout)
                          setIsWorking(false)
                        }}
                        darker
                      >
                        {removeFromCartText}
                      </BoxButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="totals">
              <h3 className="size-medium subtotal">{`${cartSubtotalText}: ${checkout.prettySubtotalPrice}`}</h3>
              <Link className="checkout-btn" to={checkout.webUrl}>
                <BoxButton>{cartCheckoutText}</BoxButton>
              </Link>
            </div>
          </div>
        )}
      </div>
      <Button onClick={hideCart} className="cart-drawer-background" />
    </div>
  )
}

export default CartDrawer
