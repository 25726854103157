import React from 'react'
import classNames from 'classnames'
import { useStore } from 'src/state'

const WorkingBar = () => {
  const { isWorking } = useStore()

  return <div className={classNames('working-bar', isWorking && 'visible')} />
}

export default WorkingBar
