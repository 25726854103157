import React from 'react'
import classNames from 'classnames'
import Button from 'src/components/button'

const BoxButton = ({ className, disabled, dark, darker, ...rest }) => {
  return (
    <Button
      className={classNames(
        'box-button',
        disabled && 'disabled',
        dark && 'dark',
        darker && 'darker',
        className
      )}
      {...rest}
    />
  )
}

export default BoxButton
