export const isBrowser = typeof window !== 'undefined'

export const setCssVar = (key, value) => {
  isBrowser && document.documentElement.style.setProperty(`--${key}`, value)
}

export const isExternalUrl = (url) => {
  try {
    if (typeof window === 'undefined') return false
    return new URL(url).origin !== window.location.origin
  } catch (err) {
    return false
  }
}
