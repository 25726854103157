import { setCssVar } from 'src/utils'

export const createActions = (set) => ({
  setNotifyMeProductHandle: (value) =>
    set((state) => ({ notifyMeProductHandle: value })),
  setIsWorking: (value) => set((state) => ({ isWorking: value })),
  setIsScrolling: (value) => set((state) => ({ isScrolling: value })),
  setHasRendered: (value) => set((state) => ({ hasRendered: value })),
  setIsWindowLoaded: (value) => set((state) => ({ isWindowLoaded: value })),
  setSiteLoaded: (value) => set((state) => ({ siteLoaded: value })),
  setCheckout: (checkout) => set((state) => ({ checkout: checkout })),
  showCart: () =>
    set((state) => ({ shouldShowCart: true, shouldShowNav: false })),
  hideCart: () => set((state) => ({ shouldShowCart: false })),
  toggleCart: () =>
    set((state) => ({
      shouldShowCart: !state.shouldShowCart,
      shouldShowNav: !state.shouldShowCart ? false : state.shouldShowNav,
    })),
  showNav: () =>
    set((state) => ({ shouldShowNav: true, shouldShowCart: false })),
  hideNav: () => set((state) => ({ shouldShowNav: false })),
  toggleNav: () =>
    set((state) => ({
      shouldShowNav: !state.shouldShowNav,
      shouldShowCart: !state.shouldShowNav ? false : state.shouldShowCart,
    })),
  showFormOverlay: () => set((state) => ({ shouldShowFormOverlay: true })),
  hideFormOverlay: () => set((state) => ({ shouldShowFormOverlay: false })),
  toggleFormOverlay: () =>
    set((state) => ({ shouldShowFormOverlay: !state.shouldShowFormOverlay })),
  setIsTransitioning: (value) => set((state) => ({ isTransitioning: value })),
  setFormOverlayId: (value) => set((state) => ({ formOverlayId: value })),
  showTrainerCardOverlay: (trainerId) =>
    set((state) => ({
      shouldShowTrainerCardOverlay: true,
      trainerCardId: trainerId,
    })),
  hideTrainerCardOverlay: () =>
    set((state) => ({ shouldShowTrainerCardOverlay: false })),
  setScrollY: (scrollY) => {
    setCssVar('scrollY', `${scrollY}px`)
    set((state) => ({ scrollY: scrollY }))
  },
  setWindowWidth: (width) => {
    setCssVar('windowWidth', `${width}px`)
    set((state) => ({
      windowWidth: width,
      isPortrait: width < state.windowHeight,
    }))
  },
  setWindowHeight: (height) => {
    setCssVar('windowHeight', `${height}px`)
    set((state) => ({
      windowHeight: height,
      isPortrait: height > state.windowWidth,
    }))
  },
  setHeaderMenuHeight: (height) => {
    setCssVar('headerMenuHeight', `${height}px`)
    set((state) => ({ headerMenuHeight: height }))
  },
  setIsScrolled: (scrollY) => {
    set((state) => {
      let isScrolled = false
      if (scrollY > state.scrollOffsetY) isScrolled = true
      return { isScrolled: isScrolled }
    })
  },
  setPagePaddingX: (windowWidth) => {
    let pagePaddingX = 25
    if (windowWidth < 401) pagePaddingX = 15
    setCssVar('pagePaddingX', `${pagePaddingX}px`)
    set((state) => ({ pagePaddingX: pagePaddingX }))
  },
  setPagePaddingTop: (windowWidth) => {
    let pagePaddingTop = 160
    if (windowWidth < 768) pagePaddingTop = 120
    setCssVar('pagePaddingTop', `${pagePaddingTop}px`)
    set((state) => ({ pagePaddingTop: pagePaddingTop }))
  },
  setPagePaddingBottom: (windowWidth) => {
    let pagePaddingBottom = 120
    if (windowWidth < 768) pagePaddingBottom = 100
    setCssVar('pagePaddingBottom', `${pagePaddingBottom}px`)
    set((state) => ({ pagePaddingBottom: pagePaddingBottom }))
  },
  setSectionPaddingBig: (windowWidth) => {
    let sectionPaddingBig = 115
    if (windowWidth < 768) sectionPaddingBig = 70
    setCssVar('sectionPaddingBig', `${sectionPaddingBig}px`)
    set((state) => ({ sectionPaddingBig: sectionPaddingBig }))
  },
  setSectionPaddingSmall: (windowWidth) => {
    let sectionPaddingSmall = 80
    if (windowWidth < 768) sectionPaddingSmall = 50
    setCssVar('sectionPaddingSmall', `${sectionPaddingSmall}px`)
    set((state) => ({ sectionPaddingSmall: sectionPaddingSmall }))
  },
  setButtonPaddingTop: (windowWidth) => {
    let buttonPaddingTop = 23
    if (windowWidth < 1024) buttonPaddingTop = 18
    if (windowWidth < 786) buttonPaddingTop = 15
    setCssVar('buttonPaddingTop', `${buttonPaddingTop}px`)
    set((state) => ({ buttonPaddingTop: buttonPaddingTop }))
  },
  setButtonPaddingBottom: (windowWidth) => {
    let buttonPaddingBottom = 18
    if (windowWidth < 1024) buttonPaddingBottom = 13
    if (windowWidth < 768) buttonPaddingBottom = 9
    setCssVar('buttonPaddingBottom', `${buttonPaddingBottom}px`)
    set((state) => ({ buttonPaddingBottom: buttonPaddingBottom }))
  },
  setContainerPaddingX: (windowWidth) => {
    set((state) => {
      let containerPaddingX = state.pagePaddingX
      if (windowWidth + containerPaddingX > state.maxContainerWidth) {
        const additionalPadding =
          (windowWidth + containerPaddingX - state.maxContainerWidth) * 0.5
        containerPaddingX += additionalPadding
      }
      setCssVar('containerPaddingX', `${containerPaddingX}px`)
      return { containerPaddingX: containerPaddingX }
    })
  },
})
