import axios from 'axios'
import Client from 'shopify-buy'
import Cookies from 'js-cookie'

const shopifyShopOrigin = process.env.GATSBY_SHOPIFY_STORE_URL
const storefrontAccessToken = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN
const checkoutCookieName = 'shop-checkout'

export const client = Client.buildClient({
  domain: shopifyShopOrigin,
  storefrontAccessToken: storefrontAccessToken,
})

export const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format

export const formatCheckout = (checkout) => {
  let totalQuantity = 0
  checkout = JSON.parse(JSON.stringify(checkout))
  checkout.prettyTotalPrice = formatPrice(checkout.totalPrice)
  checkout.prettySubtotalPrice = formatPrice(checkout.subtotalPrice)
  checkout.lineItems &&
    checkout.lineItems.forEach(({ variant, quantity }) => {
      variant.prettyPrice = formatPrice(variant.price)
      totalQuantity += quantity
    })
  checkout.totalQuantity = totalQuantity
  return checkout
}

export const removeFromCart = async (lineItemId) => {
  try {
    const checkoutId = await getCheckoutId()
    const checkout = await client.checkout.removeLineItems(checkoutId, [
      lineItemId,
    ])
    return formatCheckout(checkout)
  } catch (err) {
    console.error(err)
  }
}

export const updateQuantity = async (lineItemId, quantity) => {
  try {
    const checkoutId = await getCheckoutId()
    const checkout = await client.checkout.updateLineItems(checkoutId, [
      {
        id: lineItemId,
        quantity: quantity,
      },
    ])
    return formatCheckout(checkout)
  } catch (err) {
    console.error(err)
  }
}

export const addToCart = async (variant, quantity) => {
  const variantId = btoa(variant.admin_graphql_api_id)
  const checkoutId = await getCheckoutId()
  const checkout = await client.checkout.addLineItems(checkoutId, [
    {
      variantId: variantId,
      quantity: quantity,
    },
  ])
  return formatCheckout(checkout)
}

export const getCheckout = async () => {
  try {
    const checkoutId = await getCheckoutId()
    const checkout = await client.checkout.fetch(checkoutId)
    checkout.persistent = true
    return formatCheckout(checkout)
  } catch (err) {
    console.error(err)
    const checkout = await client.checkout.create()
    setCheckoutId(checkout.id)
    checkout.persistent = false
    return formatCheckout(checkout)
  }
}

export const refreshProduct = async (product) => {
  try {
    const clone = JSON.parse(JSON.stringify(product))
    const url = `https://${shopifyShopOrigin}/products/${product.handle}`
    const result = await axios.get(url)
    const node = document.createElement('div')
    node.innerHTML = result.data
    const clientData = JSON.parse(node.querySelector('.client-data').innerHTML)
    clone.variants.forEach((cloneVariant) => {
      const dataVariant = clientData.variants.find(
        (v) => Number(v.id) === cloneVariant.id
      )
      if (!dataVariant) return
      cloneVariant.inventory_quantity = Number(dataVariant.inventory_quantity)
    })
    clone.hasInventory = Boolean(
      clone.variants.find((v) => Number(v.inventory_quantity) > 0)
    )
    clone.isRefreshed = true
    clone.hasErrored = false
    return clone
  } catch (err) {
    console.error(err)
    return { ...product, hasErrored: true }
  }
}

export const getCheckoutId = async () => {
  return Cookies.get(checkoutCookieName)
}

export const setCheckoutId = (value) => {
  return Cookies.set(checkoutCookieName, value)
}

export const clearCheckoutId = () => {
  return Cookies.remove(checkoutCookieName)
}
