import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  TransitionProvider,
  TransitionViews,
  useTransitionStore,
} from 'gatsby-plugin-transitions'
import { useStore } from 'src/state'
import Header from 'src/components/header'
import WorkingBar from 'src/components/working-bar'
import FormOverlay from 'src/components/form-overlay'

const Layout = ({ location, children }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const {
    setHasRendered,
    hasRendered,
    pageEntryDuration,
    pageExitDuration,
    setIsTransitioning,
    isTransitioning,
    maxContainerWidth,
    windowWidth,
    siteLoaded,
    shouldShowCart,
    isWorking,
  } = useStore()
  const entryDuration = pageEntryDuration * 1000
  const exitDuration = pageExitDuration * 1000
  const isAboveMaxWidth = windowWidth > maxContainerWidth

  const TransitionState = () => {
    const [transitionState] = useTransitionStore()
    if (!shouldAnimate && transitionState.hasEntered) {
      setTimeout(() => setShouldAnimate(true))
    }
    return null
  }

  useEffect(() => {
    setHasRendered(true)
  }, [setHasRendered])

  return (
    <TransitionProvider
      location={location}
      mode="immediate"
      enter={{
        opacity: 0.99,
        zIndex: 2,
        config: {
          duration: entryDuration,
        },
        onStart: () => setIsTransitioning(true),
        onRest: () => setIsTransitioning(false),
      }}
      usual={{
        opacity: 1,
        zIndex: 1,
      }}
      leave={{
        opacity: 0.99,
        zIndex: 1,
        config: {
          duration: exitDuration,
        },
      }}
    >
      <TransitionState />
      <div
        className={classNames(
          'layout',
          hasRendered && 'has-rendered',
          isWorking && 'is-working',
          shouldShowCart && 'showing-cart',
          siteLoaded && 'site-loaded',
          shouldAnimate && 'should-animate',
          isTransitioning && 'is-transitioning',
          isAboveMaxWidth && 'is-above-max-width',
          `page-layout-${location.pathname.replace(/\//g, '')}`
        )}
      >
        <WorkingBar />
        <Header />
        <TransitionViews>{children}</TransitionViews>
        <FormOverlay />
      </div>
    </TransitionProvider>
  )
}

export default Layout
