import merge from 'merge'
import { injectedState } from './injected-state'
import { setCssVar } from '../utils'

export const initialState = {
  hasRendered: false,
  popupDelay: 0,
  isScrolling: false,
  hasShownDelayedPopup: false,
  notifyMeProductHandle: '',
  buttonPaddingTop: 23,
  buttonPaddingBottom: 18,
  formReloadDelay: 2000,
  isWorking: false,
  isWindowLoaded: false,
  shouldShowCartOnAdd: true,
  siteLoaded: false,
  checkout: null,
  shouldShowCart: false,
  shopifyShopOrigin: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  shouldShowFormOverlay: false,
  formOverlayId: '',
  shopifyCdnPathId: '1/0631/0356/6063',
  shouldShowTrainerCardOverlay: false,
  trainerCardId: '',
  containerPaddingX: 25,
  maxContainerWidth: 1440,
  headerMenuHeight: 0,
  grey: '#4A4A4A',
  liteGrey: '#b9b9b9',
  darkGrey: '#1d1d1d',
  isBrowser: typeof window !== 'undefined',
  isTransitioning: false,
  shouldShowNav: false,
  isScrolled: false,
  pageEntryDuration: 0.62,
  pageExitDuration: 0.43,
  headerSlideDuration: 0.25,
  burgerTransformDuration: 0.3,
  fadeTransitionDuration: 1,
  scrollY: 0,
  scrollOffsetY: 10,
  pagePaddingX: 25,
  layoutPaddingY: 25,
  easeOne: 'cubic-bezier(0.87, 0, 0.13, 1)',
  easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  windowWidth: 0,
  windowHeight: 0,
  pagePaddingTop: 160,
  pagePaddingBottom: 120,
  sectionPaddingBig: 115,
  sectionPaddingSmall: 80,
  formIds: [],
  trainers: [],
  isPortrait: false,
  general: {
    loadMoreProductsText: '',
    variantDefaultTitle: '',
    notifyMeText: '',
    notifyMeFormId: '',
    cartEmptyText: '',
    cartSubtotalText: '',
    cartCheckoutText: '',
    addToCartText: '',
    quantityText: '',
    soldOutText: '',
    removeFromCartText: '',
    notifyMeFormName: '',
    defaultPageTitle: '',
    defaultPageDescription: '',
    tracking: '',
    head: '',
    delayedPopupFormId: '',
  },
  header: {
    backToText: '',
    backToUrl: '',
    logo: null,
    cartButtonText: '',
  },
  footer: {
    copyrightText: '',
    privacyButtonText: '',
    termsButtonText: '',
    nyButtonText: '',
    nyFormId: '',
    nyEmail: '',
    laButtonText: '',
    laFormId: '',
    laEmail: '',
    careersButtonText: '',
    contactButtonText: '',
    socials: [],
    paymentIcons: [],
  },
}

export const createInitialState = () => {
  const state = merge.recursive(initialState, injectedState)
  setCssVar('buttonPaddingTop', `${state.buttonPaddingTop}px`)
  setCssVar('buttonPaddingBottom', `${state.buttonPaddingBottom}px`)
  setCssVar('fadeTransitionDuration', `${state.fadeTransitionDuration}s`)
  setCssVar('pageEntryDuration', `${state.pageEntryDuration}s`)
  setCssVar('pageExitDuration', `${state.pageExitDuration}s`)
  setCssVar('headerSlideDuration', `${state.headerSlideDuration}s`)
  setCssVar('burgerTransformDuration', `${state.burgerTransformDuration}s`)
  setCssVar('scrollY', `${state.scrollY}px`)
  setCssVar('grey', `${state.grey}`)
  setCssVar('darkGrey', `${state.darkGrey}`)
  setCssVar('liteGrey', `${state.liteGrey}`)
  setCssVar('maxContainerWidth', `${state.maxContainerWidth}px`)
  setCssVar('containerPaddingX', `${state.containerPaddingX}px`)
  setCssVar('headerMenuHeight', `${state.headerMenuHeight}px`)
  setCssVar('pagePaddingX', `${state.pagePaddingX}px`)
  setCssVar('layoutPaddingY', `${state.layoutPaddingY}px`)
  setCssVar('pagePaddingTop', `${state.pagePaddingTop}px`)
  setCssVar('pagePaddingBottom', `${state.pagePaddingBottom}px`)
  setCssVar('sectionPaddingBig', `${state.sectionPaddingBig}px`)
  setCssVar('sectionPaddingSmall', `${state.sectionPaddingSmall}px`)
  setCssVar('windowWidth', `${state.windowWidth}px`)
  setCssVar('windowHeight', `${state.windowHeight}px`)
  setCssVar('easeOne', `${state.easeOne}`)
  setCssVar('easeOutBack', `${state.easeOutBack}`)
  return state
}
