import path from 'path-browserify'
import React, { useState, useEffect, useRef } from 'react'
import { useStore } from 'src/state'
import classNames from 'classnames'
import placeholder from 'src/assets/placeholder.png'

const Picture = ({
  className,
  image,
  defaultWidth,
  sources = [],
  fitWidth = false,
  fitHeight = false,
  cover = false,
  contain = false,
  fadeInOnLoad = false,
  onLoad = () => {},
  style = null,
  alt = 'Unknown Description',
  ...rest
}) => {
  if (image && typeof image === 'object') image = image.src
  const shopifyCdnAlias = 'shopify://shop_images/'
  const shopifyCdnOrigin = 'https://cdn.shopify.com/s/files'
  const { shopifyCdnPathId } = useStore()
  const [loaded, setLoaded] = useState(false)
  const isString = image && typeof image === 'string'
  const shopifyThemeImageOrigin = `${shopifyCdnOrigin}/${shopifyCdnPathId}/files`
  const shopifyProductImageOrigin = `${shopifyCdnOrigin}/${shopifyCdnPathId}/products`
  const isShopifyThemeImage = isString && image.indexOf(shopifyCdnAlias) === 0
  const isShopifyProductImage =
    isString && image.indexOf(shopifyProductImageOrigin) === 0
  const [height, setHeight] = useState('100%')
  const pictureRef = useRef()
  let body = (
    <img style={{ ...style, height: height }} src={placeholder} alt={alt} />
  )

  useEffect(() => {
    const pictureNode = pictureRef.current
    const parentNode = pictureNode && pictureNode.parentNode
    if (fitHeight && parentNode) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(parentNode.offsetHeight)
      })
      resizeObserver.observe(parentNode)
      return () => parentNode && resizeObserver.unobserve(parentNode)
    }
  }, [fitHeight])

  const toShopifyBody = (fileName, origin) => {
    const src = `${origin}/${fileName}?v=latest`
    const ext = path.extname(fileName)
    const baseName = path.basename(fileName, ext)
    return (
      <>
        {sources.map((source, i) => (
          <source
            key={i}
            media={source.media}
            srcSet={`${origin}/${baseName}_${source.width}x${ext}?v=latest`}
          />
        ))}
        {defaultWidth ? (
          <img
            style={{ ...style, height: height }}
            src={`${origin}/${baseName}_${defaultWidth}x${ext}?v=latest`}
            alt={alt}
          />
        ) : (
          <img style={{ ...style, height: height }} src={src} alt={alt} />
        )}
      </>
    )
  }

  if (isShopifyThemeImage) {
    // console.log('image', image)
    const fileName = image.replace(shopifyCdnAlias, '')
    body = toShopifyBody(fileName, shopifyThemeImageOrigin)
  } else if (isShopifyProductImage) {
    const fileName = image
      .replace(`${shopifyProductImageOrigin}/`, '')
      .split('?')[0]
    body = toShopifyBody(fileName, shopifyProductImageOrigin)
  } else if (isString) {
    body = <img style={{ ...style, height: height }} src={image} alt={alt} />
  }

  return (
    <picture
      ref={pictureRef}
      style={{ height: height }}
      onLoad={(event) => {
        setLoaded(true)
        onLoad(event)
      }}
      className={classNames(
        'picture',
        fitWidth && 'fit-width',
        fitHeight && 'fit-height',
        cover && 'cover',
        contain && 'contain',
        fadeInOnLoad && 'fade-in-on-load',
        loaded && 'loaded',
        className
      )}
      {...rest}
    >
      {body}
    </picture>
  )
}

export default Picture
