import React from 'react'
import classNames from 'classnames'
import { useStore } from 'src/state'
import Button from 'src/components/button'
import Picture from 'src/components/picture'
import closeBtn from 'src/assets/close-btn.svg'

const FormOverlay = () => {
  const { shouldShowFormOverlay, formOverlayId, hideFormOverlay, formIds } =
    useStore()
  return (
    <div
      className={classNames('form-overlay', shouldShowFormOverlay && 'visible')}
    >
      <Button onClick={hideFormOverlay} className="overlay-background" />
      <div className="form-overlay-container">
        <Button onClick={hideFormOverlay} className="close-btn">
          <Picture image={closeBtn} />
        </Button>
        {formIds.map((formId) => (
          <div
            key={formId}
            className={classNames(
              'klaviyo-form-container',
              formId === formOverlayId && 'visible'
            )}
          >
            <div className={formId} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FormOverlay
