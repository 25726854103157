import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import { useStore } from 'src/state'

const AspectBox = ({
  className,
  x = 16,
  y = 9,
  style = {},
  children,
  ...rest
}) => {
  const ratio = y / x
  const [height, setHeight] = useState(0)
  const { isBrowser } = useStore()
  const ref = useRef()

  useEffect(() => {
    if (!isBrowser) return
    const update = () => {
      const node = ref.current
      if (!node) return
      const height = node.offsetWidth * ratio
      setHeight(height)
    }
    window.addEventListener('resize', update)
    update()
    return () => window.removeEventListener('resize', update)
  }, [isBrowser, ratio])

  return (
    <div
      ref={ref}
      className={classNames('aspect-box', className)}
      style={{
        ...style,
        height: height,
      }}
    >
      {children}
    </div>
  )
}

export default AspectBox
