import React from 'react'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import { isExternalUrl } from 'src/utils'

const Link = ({ to, className, children, newTab = false, ...rest }) => {
  if (newTab) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        className={classNames('link', className)}
        href={to}
        {...rest}
      >
        {children}
      </a>
    )
  }

  if (isExternalUrl(to)) {
    return (
      <a className={classNames('link', className)} href={to} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink className={classNames('link', className)} to={to} {...rest}>
      {children}
    </GatsbyLink>
  )
}

export default Link
